var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"AllInactiveFactures"},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":true,"color":"black"},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('CRow',[_c('CCol',[_c('h1',[_vm._v(" Factures archivées du cabinet ")])])],1),_c('CCard',{staticClass:"mt-3"},[_c('CCardBody',[_c('CDataTable',{staticClass:"cursor-table",attrs:{"hover":"","items":_vm.inactiveFacturesRender,"fields":_vm.inactiveFacturesRenderFields,"noItemsView":{ noResults: "Aucun résultat", noItems: "Aucun résultat" }},on:{"row-clicked":_vm.goToFacturePage},scopedSlots:_vm._u([{key:"date_facturation",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(_vm.$dayjs(item.date_facturation).format('DD/MM/YYYY')))])])]}},{key:"date_echeance",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(_vm.$dayjs(item.date_echeance).format('DD/MM/YYYY')))])])]}},{key:"montant_total_ht",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatThousandSeparatorNumber(parseFloat(item.montant_total_ht).toFixed(2)))+" € ")])]}},{key:"montant_total_ttc",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatThousandSeparatorNumber(parseFloat(item.montant_total_ttc).toFixed(2)))+" € ")])]}},{key:"montant_total_paid",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatThousandSeparatorNumber(parseFloat(item.montant_total_paid).toFixed(2)))+" € ")])]}}])}),_c('CRow',[_c('CCol',{staticClass:"text-center"},[(_vm.getAllInactiveFacturesUrl)?_c('CButton',{staticClass:"px-4",attrs:{"color":"outline-dark"},on:{"click":function($event){return _vm.getAllInactiveFactures()}}},[_vm._v(" Voir plus ")]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="AllInactiveFactures">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CRow>
      <CCol>
        <h1> Factures archivées du cabinet </h1>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardBody>

        <CDataTable
          class="cursor-table"
          hover
          :items="inactiveFacturesRender"
          :fields="inactiveFacturesRenderFields"
          @row-clicked="goToFacturePage"
          :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
        >
        <template #date_facturation="{item}">
          <td>
           <span>{{$dayjs(item.date_facturation).format('DD/MM/YYYY')}}</span>
          </td>
        </template>
        <template #date_echeance="{item}">
          <td>
           <span>{{$dayjs(item.date_echeance).format('DD/MM/YYYY')}}</span>
          </td>
        </template>
        <template #montant_total_ht="{item}">
          <td>
            {{formatThousandSeparatorNumber(parseFloat(item.montant_total_ht).toFixed(2))}} €
          </td>
        </template>
        <template #montant_total_ttc="{item}">
          <td>
            {{formatThousandSeparatorNumber(parseFloat(item.montant_total_ttc).toFixed(2))}} €
          </td>
        </template>
        <template #montant_total_paid="{item}">
          <td>
            {{formatThousandSeparatorNumber(parseFloat(item.montant_total_paid).toFixed(2))}} €
          </td>
        </template>

        </CDataTable>
        <CRow>
          <CCol class="text-center">
            <CButton
              v-if="getAllInactiveFacturesUrl"
              @click="getAllInactiveFactures()"
              class="px-4"
              color="outline-dark">
              Voir plus
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { apiBaseUrl } from '@/variables/localVariables'

import { APIManagerConnected } from '@/api/APIManagerConnected'

import renderMixins from '@/mixins/renderMixins'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiManagerConnected = new APIManagerConnected()

export default {
  name: 'AllInactiveFactures',
  components: {
    Loading,
  },
  mixins: [
    renderMixins
  ],
  data: function () {
    return {
      // ------------- MISSION -----------
      inactiveFactures: [],
      inactiveFacturesRender: [],
      inactiveFacturesRenderFields: [
        { key: "facture_number", label: "#"},
        { key: "client_name", label: "Client"},
        { key: "facture_title", label: "Intitulé"},
        { key: "date_facturation", label: "Date de facturation", sortable: true },
        { key: "date_echeance", label: "Date d'échéance", sortable: true },
        { key: "montant_total_ht", label: "Montant total HT", sortable: true },
        { key: "montant_total_ttc", label: "Montant total TTC", sortable: true },
        { key: "montant_total_paid", label: "Montant payé", sortable: true },
      ],
      isAllInactiveFacturesLoading: false,


      basegetAllInactiveFacturesUrl: apiBaseUrl + '/all-inactive-factures',
      getAllInactiveFacturesUrl: apiBaseUrl + '/all-inactive-factures',
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isAllInactiveFacturesLoading) {
        return true
      }
      return false
    }
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.getAllInactiveFactures()
  },
  watch: {
    inactiveFactures: function (newFactures) {
      if (newFactures.length == 0) {
        this.inactiveFacturesRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < newFactures.length; i++) {
          var temp_data = {
            'id': newFactures[i].id,
            'facture_number': newFactures[i].facture_number,
            'client_name': newFactures[i].client_name,
            'mission_name': newFactures[i].mission_name,
            'facture_title': newFactures[i].facture_title,
            'date_facturation': newFactures[i].date_facturation,
            'date_echeance': newFactures[i].date_echeance,
            'montant_total_ht': newFactures[i].montant_total_ht,
            'montant_total_ttc': newFactures[i].montant_total_ttc,
            'montant_total_paid': newFactures[i].montant_total_paid,
          }
          var today = this.$dayjs()
          var echeanceDate = this.$dayjs(newFactures[i].date_echeance)
          if (parseFloat(newFactures[i].montant_total_paid).toFixed(2) == parseFloat(newFactures[i].montant_total_ttc).toFixed(2)) {
            temp_data['_classes'] = 'table-success'
          }
          else if (today > echeanceDate) {
            temp_data['_classes'] = 'table-danger'
          }

          final_array.push(temp_data)
        }
        this.inactiveFacturesRender = final_array
      }
    },
  },
  methods: {
    getAllInactiveFactures () {
      this.isAllInactiveFacturesLoading = true

      apiManagerConnected.getAllInactiveFactures(this.token, this.getAllInactiveFacturesUrl)
      .then((result) => {
        this.inactiveFactures = this.inactiveFactures.concat(result.data.results)
        this.getAllInactiveFacturesUrl = result.data.next
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllInactiveFacturesLoading = false
      })
    },

    // ------------ ROUTER FUNCTIONS -------------------
    goToFacturePage(item) {
      this.$router.push('/modify-facture/' + item.id)
    }

  }

}
</script>
